// extracted by mini-css-extract-plugin
export var root = "PlasmicValueprop-module--root--3WQij";
export var root__vertical = "PlasmicValueprop-module--root__vertical--OZ9Gp";
export var root__card = "PlasmicValueprop-module--root__card--2mWIi";
export var box__zIf9O = "PlasmicValueprop-module--box__zIf9O--3GgAB";
export var box__flat__zIf9OU5RWe = "PlasmicValueprop-module--box__flat__zIf9OU5RWe--1w1eX";
export var box__vertical__zIf9OOfbJb = "PlasmicValueprop-module--box__vertical__zIf9OOfbJb--2ZQcM";
export var box__card__zIf9OTrmC1 = "PlasmicValueprop-module--box__card__zIf9OTrmC1--PT_Us";
export var box__flatIcon__zIf9O3YXxb = "PlasmicValueprop-module--box__flatIcon__zIf9O3YXxb--3e99l";
export var img__groKy = "PlasmicValueprop-module--img__groKy--3a1Dc";
export var box__dydz4 = "PlasmicValueprop-module--box__dydz4--rKTSH";
export var box__flat__dydz4U5RWe = "PlasmicValueprop-module--box__flat__dydz4U5RWe--3XHW6";
export var box__vertical__dydz4OfbJb = "PlasmicValueprop-module--box__vertical__dydz4OfbJb--2AXQv";
export var box__card__dydz4TrmC1 = "PlasmicValueprop-module--box__card__dydz4TrmC1--1LMrq";
export var box__flatIcon__dydz43YXxb = "PlasmicValueprop-module--box__flatIcon__dydz43YXxb--dyku2";
export var svg__fwRpz = "PlasmicValueprop-module--svg__fwRpz--1yYuW";
export var box__qnaS9 = "PlasmicValueprop-module--box__qnaS9--KMN8R";
export var slotChildren = "PlasmicValueprop-module--slotChildren--20Pu_";
export var slotChildren__dark = "PlasmicValueprop-module--slotChildren__dark--1LUqw";
export var slotSlot = "PlasmicValueprop-module--slotSlot--iNgp1";
export var slotSlot__dark = "PlasmicValueprop-module--slotSlot__dark--gIYkb";