// extracted by mini-css-extract-plugin
export var root = "PlasmicShopBanners-module--root--1a0Ay";
export var banner__ik6Zd = "PlasmicShopBanners-module--banner__ik6Zd--3Zw8O";
export var img__puSx = "PlasmicShopBanners-module--img__puSx--Vaix4";
export var box__yEW0 = "PlasmicShopBanners-module--box__yEW0--13wzQ";
export var box__bwbLx = "PlasmicShopBanners-module--box__bwbLx--SAWlI";
export var svg__rm0VJ = "PlasmicShopBanners-module--svg__rm0VJ--25xka";
export var box__zpNIr = "PlasmicShopBanners-module--box__zpNIr--R_GnE";
export var svg__unJrC = "PlasmicShopBanners-module--svg__unJrC--4eGyI";
export var box__h7Rwk = "PlasmicShopBanners-module--box__h7Rwk--3p0TC";
export var img___1O4Kj = "PlasmicShopBanners-module--img___1O4Kj--2toRm";
export var box___4QxRf = "PlasmicShopBanners-module--box___4QxRf--keYJD";
export var box__itye = "PlasmicShopBanners-module--box__itye--39pQV";
export var svg__waGpe = "PlasmicShopBanners-module--svg__waGpe--3M7SI";
export var box__fx86 = "PlasmicShopBanners-module--box__fx86--2nyrR";
export var svg__j2Bqb = "PlasmicShopBanners-module--svg__j2Bqb--1aBWt";
export var banner___7KzmK = "PlasmicShopBanners-module--banner___7KzmK--6253_";
export var img__cNMp7 = "PlasmicShopBanners-module--img__cNMp7--3jjQl";
export var box___5RKsu = "PlasmicShopBanners-module--box___5RKsu--1V1Is";
export var box__ozXh8 = "PlasmicShopBanners-module--box__ozXh8--3A1Hs";
export var svg__gCwRu = "PlasmicShopBanners-module--svg__gCwRu--2PEJ1";
export var box__l49S3 = "PlasmicShopBanners-module--box__l49S3--1QT6j";
export var svg__giOdJ = "PlasmicShopBanners-module--svg__giOdJ--RjVTh";