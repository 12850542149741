// extracted by mini-css-extract-plugin
export var root = "PlasmicNavigation-module--root--bfxeZ";
export var root__lightBg = "PlasmicNavigation-module--root__lightBg--jr-ep";
export var box__mjYs6 = "PlasmicNavigation-module--box__mjYs6--1Dz6m";
export var logo___5OeKk = "PlasmicNavigation-module--logo___5OeKk--Dlel1";
export var box__wa8W = "PlasmicNavigation-module--box__wa8W--wsgZZ";
export var button__ijSre = "PlasmicNavigation-module--button__ijSre--3EgQk";
export var svg__k5Yo = "PlasmicNavigation-module--svg__k5Yo--3-iiN";
export var svg__hLzzb = "PlasmicNavigation-module--svg__hLzzb--235gU";
export var button__oGqfa = "PlasmicNavigation-module--button__oGqfa--1j0Ny";
export var svg__xGaWq = "PlasmicNavigation-module--svg__xGaWq--3IYFu";
export var svg___30UL = "PlasmicNavigation-module--svg___30UL--2lrPb";
export var button__opQ7T = "PlasmicNavigation-module--button__opQ7T--2haKC";
export var svg__okg1S = "PlasmicNavigation-module--svg__okg1S--3JkMH";
export var svg___1BZmV = "PlasmicNavigation-module--svg___1BZmV--2s-Vy";
export var button__eOohf = "PlasmicNavigation-module--button__eOohf--2gW9u";
export var svg__k3Nfh = "PlasmicNavigation-module--svg__k3Nfh--1A9zw";
export var svg__rK4CL = "PlasmicNavigation-module--svg__rK4CL--_afuI";
export var button__l4POk = "PlasmicNavigation-module--button__l4POk--avWGc";
export var svg___2KYCb = "PlasmicNavigation-module--svg___2KYCb--3LtS2";
export var svg__bpz2C = "PlasmicNavigation-module--svg__bpz2C--2ATM1";
export var button__e8Tsu = "PlasmicNavigation-module--button__e8Tsu--2I4v-";
export var svg__xfikg = "PlasmicNavigation-module--svg__xfikg--27_Zi";
export var svg__zkNfC = "PlasmicNavigation-module--svg__zkNfC--3A3Ro";
export var box__yOZcW = "PlasmicNavigation-module--box__yOZcW--1Utlz";
export var button__juFfb = "PlasmicNavigation-module--button__juFfb--2H-i2";
export var button__dark__juFfbokm6S = "PlasmicNavigation-module--button__dark__juFfbokm6S--3kKva";
export var button___9U78 = "PlasmicNavigation-module--button___9U78--1yJdJ";
export var svg__puAg = "PlasmicNavigation-module--svg__puAg--2qfQz";
export var svg__g4K9 = "PlasmicNavigation-module--svg__g4K9--399Pl";