// extracted by mini-css-extract-plugin
export var root = "PlasmicValuesSection3-module--root--1HkC5";
export var foreground = "PlasmicValuesSection3-module--foreground--2yaCl";
export var box__ljAlN = "PlasmicValuesSection3-module--box__ljAlN--75dbC";
export var box__ezl2Z = "PlasmicValuesSection3-module--box__ezl2Z--3WSga";
export var h2__erSTw = "PlasmicValuesSection3-module--h2__erSTw--7apzQ";
export var box__c5Ek9 = "PlasmicValuesSection3-module--box__c5Ek9--1q3fB";
export var box__kGDo1 = "PlasmicValuesSection3-module--box__kGDo1--MexdY";
export var box__tfafe = "PlasmicValuesSection3-module--box__tfafe--J5g5f";
export var valueprop___9AwE = "PlasmicValuesSection3-module--valueprop___9AwE--3o2L0";
export var svg__xMcXz = "PlasmicValuesSection3-module--svg__xMcXz--1vnDP";
export var svg__kV6Vo = "PlasmicValuesSection3-module--svg__kV6Vo--2OCoI";
export var valueprop__doJ5M = "PlasmicValuesSection3-module--valueprop__doJ5M--1iBhV";
export var svg__dI8Da = "PlasmicValuesSection3-module--svg__dI8Da--1sO6Y";
export var svg__nF2Ih = "PlasmicValuesSection3-module--svg__nF2Ih--1eXQz";
export var valueprop__akIRs = "PlasmicValuesSection3-module--valueprop__akIRs--3z-tD";
export var svg__m6Fg8 = "PlasmicValuesSection3-module--svg__m6Fg8--AZb0G";
export var svg__x6UUa = "PlasmicValuesSection3-module--svg__x6UUa--LqNYm";
export var valueprop__iyePd = "PlasmicValuesSection3-module--valueprop__iyePd--2NEHR";
export var svg__sh6KR = "PlasmicValuesSection3-module--svg__sh6KR--cwJ49";
export var svg__w6Pno = "PlasmicValuesSection3-module--svg__w6Pno--CKPl7";
export var box__q3FVh = "PlasmicValuesSection3-module--box__q3FVh--36wE8";
export var box___21I7C = "PlasmicValuesSection3-module--box___21I7C--m4Q-A";
export var box__vf0PT = "PlasmicValuesSection3-module--box__vf0PT--2sw_I";
export var valueprop__c0FY = "PlasmicValuesSection3-module--valueprop__c0FY--1GSyT";
export var svg__mZ9Ak = "PlasmicValuesSection3-module--svg__mZ9Ak--2aNL0";
export var svg___9MmK = "PlasmicValuesSection3-module--svg___9MmK--3DI9T";
export var box__ejP8L = "PlasmicValuesSection3-module--box__ejP8L--2vnqa";
export var valueprop___4Rszp = "PlasmicValuesSection3-module--valueprop___4Rszp--LWTbb";
export var svg__lPhQc = "PlasmicValuesSection3-module--svg__lPhQc--aauGR";
export var svg__ty4BQ = "PlasmicValuesSection3-module--svg__ty4BQ--qJgri";
export var valueprop__t95Gi = "PlasmicValuesSection3-module--valueprop__t95Gi--NZXMr";
export var svg__lXrCp = "PlasmicValuesSection3-module--svg__lXrCp--2jos7";
export var svg__jRZxP = "PlasmicValuesSection3-module--svg__jRZxP--U95Qk";
export var valueprop__cGr6F = "PlasmicValuesSection3-module--valueprop__cGr6F--3lhqV";
export var svg__rj89 = "PlasmicValuesSection3-module--svg__rj89--3ukE8";
export var svg__jlXp1 = "PlasmicValuesSection3-module--svg__jlXp1--38LoX";