// extracted by mini-css-extract-plugin
export var root = "PlasmicValuesSection2Dark-module--root--1W-i1";
export var foreground = "PlasmicValuesSection2Dark-module--foreground--U1hWo";
export var box__b1Gab = "PlasmicValuesSection2Dark-module--box__b1Gab--ZNFll";
export var box___11BAu = "PlasmicValuesSection2Dark-module--box___11BAu--wsFpz";
export var h2__hFbDu = "PlasmicValuesSection2Dark-module--h2__hFbDu--1nIe4";
export var box__mxxnu = "PlasmicValuesSection2Dark-module--box__mxxnu--2lV6H";
export var button__e8H4X = "PlasmicValuesSection2Dark-module--button__e8H4X--3GnS-";
export var svg__wGAc = "PlasmicValuesSection2Dark-module--svg__wGAc--a1HZ1";
export var box__vsOea = "PlasmicValuesSection2Dark-module--box__vsOea--34Dl9";
export var svg__haCm0 = "PlasmicValuesSection2Dark-module--svg__haCm0--1twtp";
export var box__oxJMi = "PlasmicValuesSection2Dark-module--box__oxJMi--17UXS";
export var listItem__sFhVq = "PlasmicValuesSection2Dark-module--listItem__sFhVq--3fx3r";
export var box__fygbd = "PlasmicValuesSection2Dark-module--box__fygbd--3F8LY";
export var svg__h6Dvt = "PlasmicValuesSection2Dark-module--svg__h6Dvt--3JObX";
export var listItem___756Rd = "PlasmicValuesSection2Dark-module--listItem___756Rd--3_uST";
export var box___3Gurg = "PlasmicValuesSection2Dark-module--box___3Gurg--2wnDT";
export var svg__yHqIf = "PlasmicValuesSection2Dark-module--svg__yHqIf--2VUBt";
export var listItem__uhsHh = "PlasmicValuesSection2Dark-module--listItem__uhsHh--1Kbhv";
export var box__rcCa2 = "PlasmicValuesSection2Dark-module--box__rcCa2--1Z6oS";
export var svg___4UTqj = "PlasmicValuesSection2Dark-module--svg___4UTqj--2bjht";
export var listItem__zJd75 = "PlasmicValuesSection2Dark-module--listItem__zJd75--35YbT";
export var box__yys7 = "PlasmicValuesSection2Dark-module--box__yys7--3Pk8E";
export var svg__w9S75 = "PlasmicValuesSection2Dark-module--svg__w9S75--17LOU";
export var listItem__sh1Ah = "PlasmicValuesSection2Dark-module--listItem__sh1Ah--2HLlw";
export var box__qjp4C = "PlasmicValuesSection2Dark-module--box__qjp4C--3pJkv";
export var svg__rts6U = "PlasmicValuesSection2Dark-module--svg__rts6U--15Dsw";