// extracted by mini-css-extract-plugin
export var root = "PlasmicBanner-module--root--1A_Xz";
export var right = "PlasmicBanner-module--right--3yG_5";
export var img__etYmU = "PlasmicBanner-module--img__etYmU--357p2";
export var box = "PlasmicBanner-module--box--3G_Ii";
export var left = "PlasmicBanner-module--left--1jwCC";
export var box__dFytJ = "PlasmicBanner-module--box__dFytJ--35N4z";
export var box__afpjn = "PlasmicBanner-module--box__afpjn--2IyfP";
export var svg__bvUZa = "PlasmicBanner-module--svg__bvUZa--1qahu";
export var box__tCTiY = "PlasmicBanner-module--box__tCTiY--27X7x";
export var svg__bFxz3 = "PlasmicBanner-module--svg__bFxz3--m6RHl";