// extracted by mini-css-extract-plugin
export var root = "PlasmicPricingSection3-module--root--1BdWg";
export var box = "PlasmicPricingSection3-module--box--3aOqk";
export var foreground = "PlasmicPricingSection3-module--foreground--1Fnlx";
export var box__vxQT = "PlasmicPricingSection3-module--box__vxQT--15ViF";
export var box__jImVu = "PlasmicPricingSection3-module--box__jImVu--3bnOz";
export var box__czIT = "PlasmicPricingSection3-module--box__czIT--1cANY";
export var box___4OQpb = "PlasmicPricingSection3-module--box___4OQpb--2l5aT";
export var box__n9Tep = "PlasmicPricingSection3-module--box__n9Tep--2HF59";
export var box__nmCfv = "PlasmicPricingSection3-module--box__nmCfv--2gm78";
export var box__dyc6X = "PlasmicPricingSection3-module--box__dyc6X--30wiE";
export var box__nRMoW = "PlasmicPricingSection3-module--box__nRMoW--2ajfb";
export var box__jOr73 = "PlasmicPricingSection3-module--box__jOr73--22JGO";
export var box__y24Ge = "PlasmicPricingSection3-module--box__y24Ge--3Hw32";
export var box___1Q0SV = "PlasmicPricingSection3-module--box___1Q0SV--3iDPb";
export var box___8ZNq8 = "PlasmicPricingSection3-module--box___8ZNq8--1kHN_";
export var box__ilKkF = "PlasmicPricingSection3-module--box__ilKkF--3-2sa";
export var divider__pwXLi = "PlasmicPricingSection3-module--divider__pwXLi--B1UTj";
export var box__mKumc = "PlasmicPricingSection3-module--box__mKumc--30bo8";
export var box__hlXvz = "PlasmicPricingSection3-module--box__hlXvz--3iszd";
export var valueprop__je8Qg = "PlasmicPricingSection3-module--valueprop__je8Qg--343RT";
export var svg__dryKq = "PlasmicPricingSection3-module--svg__dryKq--2L3-U";
export var svg__sha1Q = "PlasmicPricingSection3-module--svg__sha1Q--2sV3W";
export var valueprop___33LYa = "PlasmicPricingSection3-module--valueprop___33LYa--1Xsex";
export var svg__ner7B = "PlasmicPricingSection3-module--svg__ner7B--2J9PL";
export var svg__f2Bnq = "PlasmicPricingSection3-module--svg__f2Bnq--5RdBc";
export var svg__rh6VU = "PlasmicPricingSection3-module--svg__rh6VU--e_7El";
export var box__dlztQ = "PlasmicPricingSection3-module--box__dlztQ--2vetj";
export var svg__d8DOx = "PlasmicPricingSection3-module--svg__d8DOx--3nb3E";
export var box__xrrIn = "PlasmicPricingSection3-module--box__xrrIn--30CVt";
export var box__f3VLi = "PlasmicPricingSection3-module--box__f3VLi--2Npb0";
export var box__h7Y90 = "PlasmicPricingSection3-module--box__h7Y90--2-2bm";
export var box__nf9Z = "PlasmicPricingSection3-module--box__nf9Z--557WJ";
export var box__nel9X = "PlasmicPricingSection3-module--box__nel9X--3440j";
export var box__k7OuP = "PlasmicPricingSection3-module--box__k7OuP--1slq8";
export var box__oJmta = "PlasmicPricingSection3-module--box__oJmta--1nPh6";
export var box__ibiRr = "PlasmicPricingSection3-module--box__ibiRr--jrOHg";
export var divider__pw5NJ = "PlasmicPricingSection3-module--divider__pw5NJ--3NqfE";
export var box___4PMf3 = "PlasmicPricingSection3-module--box___4PMf3--1t4UL";
export var box__k6Kse = "PlasmicPricingSection3-module--box__k6Kse--4ADvo";
export var valueprop__oSmv = "PlasmicPricingSection3-module--valueprop__oSmv--2aCk_";
export var svg__bHiEl = "PlasmicPricingSection3-module--svg__bHiEl--1E-o4";
export var svg__jinDz = "PlasmicPricingSection3-module--svg__jinDz--91UhM";
export var valueprop__s9OYo = "PlasmicPricingSection3-module--valueprop__s9OYo--3_VM-";
export var box__wrNnW = "PlasmicPricingSection3-module--box__wrNnW--2s-3E";
export var svg__qUdCt = "PlasmicPricingSection3-module--svg__qUdCt--2XTIg";
export var svg__iPNp2 = "PlasmicPricingSection3-module--svg__iPNp2--3Hke0";
export var valueprop___3RQzh = "PlasmicPricingSection3-module--valueprop___3RQzh--1N3O0";
export var svg__n73P4 = "PlasmicPricingSection3-module--svg__n73P4--3WXhV";
export var svg__cnG6B = "PlasmicPricingSection3-module--svg__cnG6B--1-1xK";
export var valueprop__mKl2Q = "PlasmicPricingSection3-module--valueprop__mKl2Q--1Cejf";
export var box___93XQ = "PlasmicPricingSection3-module--box___93XQ--EjIoG";
export var svg___9XNdL = "PlasmicPricingSection3-module--svg___9XNdL--1Ag2D";
export var svg__sL9Fe = "PlasmicPricingSection3-module--svg__sL9Fe--2BaZO";
export var svg__y58Fc = "PlasmicPricingSection3-module--svg__y58Fc--xFXdQ";
export var box__lpT75 = "PlasmicPricingSection3-module--box__lpT75--HCNcM";
export var svg__gsDro = "PlasmicPricingSection3-module--svg__gsDro--IaYwf";
export var box___0AV4A = "PlasmicPricingSection3-module--box___0AV4A--2qKIE";
export var box__i7Ivm = "PlasmicPricingSection3-module--box__i7Ivm--2SPtg";
export var box__axan = "PlasmicPricingSection3-module--box__axan--vBK0v";
export var box__gSwXb = "PlasmicPricingSection3-module--box__gSwXb--XbM8X";
export var box__mm9Jl = "PlasmicPricingSection3-module--box__mm9Jl--12Svb";
export var box___8Fdzq = "PlasmicPricingSection3-module--box___8Fdzq--g43AD";
export var box__qCr6S = "PlasmicPricingSection3-module--box__qCr6S--2Vna0";
export var box__rXzTd = "PlasmicPricingSection3-module--box__rXzTd--12cqW";
export var divider__sBi = "PlasmicPricingSection3-module--divider__sBi--s7C6H";
export var box__giReh = "PlasmicPricingSection3-module--box__giReh--2z8LT";
export var box___9697S = "PlasmicPricingSection3-module--box___9697S--3j4qk";
export var valueprop___3HpkF = "PlasmicPricingSection3-module--valueprop___3HpkF--1_3uX";
export var svg___683EV = "PlasmicPricingSection3-module--svg___683EV--3NpG-";
export var svg__yao3W = "PlasmicPricingSection3-module--svg__yao3W--nT2RR";
export var valueprop__bLr2X = "PlasmicPricingSection3-module--valueprop__bLr2X--3wYfQ";
export var svg__lentl = "PlasmicPricingSection3-module--svg__lentl--3De7k";
export var svg__jpAke = "PlasmicPricingSection3-module--svg__jpAke--rM2bq";
export var svg__mSgje = "PlasmicPricingSection3-module--svg__mSgje--1GBoS";
export var box__hocAh = "PlasmicPricingSection3-module--box__hocAh--1Yz6e";
export var svg___09ASd = "PlasmicPricingSection3-module--svg___09ASd--3aVGp";