// extracted by mini-css-extract-plugin
export var root = "PlasmicTestimonialsSection1-module--root--1ALi-";
export var box = "PlasmicTestimonialsSection1-module--box--3gnwK";
export var testimonial__zwbc7 = "PlasmicTestimonialsSection1-module--testimonial__zwbc7--1TYU2";
export var img__pn1Za = "PlasmicTestimonialsSection1-module--img__pn1Za--1Ay3r";
export var testimonial__dglFj = "PlasmicTestimonialsSection1-module--testimonial__dglFj--2jyE3";
export var img__svs7I = "PlasmicTestimonialsSection1-module--img__svs7I--1b2Zw";
export var testimonial__cmjXh = "PlasmicTestimonialsSection1-module--testimonial__cmjXh--vafN6";
export var box__sxkwb = "PlasmicTestimonialsSection1-module--box__sxkwb--3MIQF";
export var box__sskyJ = "PlasmicTestimonialsSection1-module--box__sskyJ--1Radt";
export var img__j0HCc = "PlasmicTestimonialsSection1-module--img__j0HCc--2DCPo";
export var testimonial__rdvKb = "PlasmicTestimonialsSection1-module--testimonial__rdvKb--tGCvw";
export var box__eMk12 = "PlasmicTestimonialsSection1-module--box__eMk12--2SaSL";
export var img___66Ct = "PlasmicTestimonialsSection1-module--img___66Ct--2rUDz";