// extracted by mini-css-extract-plugin
export var root = "PlasmicButton-module--root--1RFOU";
export var root__startIcon = "PlasmicButton-module--root__startIcon--2eoc3";
export var root__colors_green = "PlasmicButton-module--root__colors_green--k2VdA";
export var root__colors_indigo = "PlasmicButton-module--root__colors_indigo--28a34";
export var root__colors_blue = "PlasmicButton-module--root__colors_blue--NAc0A";
export var root__colors_red = "PlasmicButton-module--root__colors_red--dWPzt";
export var root__colors_white = "PlasmicButton-module--root__colors_white--2Z7_l";
export var root__round = "PlasmicButton-module--root__round--2sN9V";
export var root__flat = "PlasmicButton-module--root__flat--3J85J";
export var root__noGap = "PlasmicButton-module--root__noGap--gkHwn";
export var root__extraSmallShadow = "PlasmicButton-module--root__extraSmallShadow--36NHA";
export var root__noLabel = "PlasmicButton-module--root__noLabel--2RH-S";
export var root__ouline = "PlasmicButton-module--root__ouline--XEMIn";
export var root__darkGray = "PlasmicButton-module--root__darkGray--12JRF";
export var root__bgDifference = "PlasmicButton-module--root__bgDifference--2wxSr";
export var root__linkLabel = "PlasmicButton-module--root__linkLabel--1knRQ";
export var svg__lDTlT = "PlasmicButton-module--svg__lDTlT--2OH1F";
export var slotSlot = "PlasmicButton-module--slotSlot--3uFiz";
export var slotSlot__colors_green = "PlasmicButton-module--slotSlot__colors_green--3eowR";
export var slotSlot__colors_indigo = "PlasmicButton-module--slotSlot__colors_indigo--38hpP";
export var slotSlot__colors_blue = "PlasmicButton-module--slotSlot__colors_blue--2AHJy";
export var slotSlot__colors_red = "PlasmicButton-module--slotSlot__colors_red--m7Iqj";
export var slotSlot__large = "PlasmicButton-module--slotSlot__large--PAnH4";
export var slotSlot__linkLabel = "PlasmicButton-module--slotSlot__linkLabel--1OK_M";
export var slotSlot__navLink = "PlasmicButton-module--slotSlot__navLink--3qEyr";
export var slotSlot__darkGray = "PlasmicButton-module--slotSlot__darkGray--3KDpY";
export var slotSlot__dark = "PlasmicButton-module--slotSlot__dark--i9Gmh";
export var root__navLink = "PlasmicButton-module--root__navLink--EVoRm";
export var __wab_slot = "PlasmicButton-module--__wab_slot--2xT0V";
export var svg__uGmyj = "PlasmicButton-module--svg__uGmyj--1lJHt";